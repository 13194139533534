<template>
  <div v-if="loaded">
    <site-select-item
      v-for="site in sites"
      :key="`site-${site.siteID}`"
      :site="site"
      :content-remaining="
        contentRemaining ? contentRemaining[site.siteID] : null
      "
      :max-categories="maxCategories ? maxCategories[site.siteID] : null"
      v-model="value[site.siteID]"
      @archive="archive"
    ></site-select-item>
  </div>
</template>

<script>
import SiteSelectItem from "./SiteSelectItem.vue";
export default {
  components: { SiteSelectItem },
  name: "content.upload.site-select-list",
  props: {
    value: Object,
    contentRemaining: Object,
    maxCategories: Object,
    excludeSites: Array,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    sites() {
      let sites = [...this.$auth.activeCompany.sites].sort(
        (a, b) => a.siteRank - b.siteRank
      );

      if (this.excludeSites) {
        sites = sites.filter((s) => !this.excludeSites.includes(s.siteID));
      }
      return sites;
    },
  },
  methods: {
    archive(content) {
      this.$emit("archive", content);
    },
  },
  mounted() {
    let obj = {};
    for (let i = 0; i < this.sites.length; i++) {
      if (this.value[this.sites[i].siteID]) {
        obj[this.sites[i].siteID] = this.value[this.sites[i].siteID];
      } else {
        obj[this.sites[i].siteID] = [];
      }
    }
    this.$emit("input", obj);
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
};
</script>
