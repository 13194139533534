import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getCategoryById(category) {
    let res = await http.get("api/Category/" + category.category_id);
    return res.data;
  },

  async getCategoriesBulk(categories) {
    let res = await http.post("api/category/bulk", categories);
    return res.data;
  },

  async getSiteCategories(site) {
    let res = await http.get("api/sites/categories/" + site);
    return res.data;
  },

  async getSiteCategoryListings(site, company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/sites/categoryListings/${site}/${company}`,
      authHeaders
    );
    return res.data;
  },

  async getSiteAllCategoryListings(site, company) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/sites/allCategoryListings/${site}/${company}`,
      authHeaders
    );
    return res.data;
  },

  async getSiteSubCategoryListings(site, company, parent) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.get(
      `api/sites/subCategoryListings/${site}/${company}/${parent}`,
      authHeaders
    );
    return res.data;
  },

  async getSubcategories(parent) {
    let res = await http.get("api/sites/subcategories/" + parent);
    return res.data;
  },
};
