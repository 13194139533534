<template>
  <div :class="['row', 'border', 'p-2', 'rounded', 'mb-1', 'ms-0']">
    <div :class="[!saved && done ? 'col-md-5' : 'col-md-6']" v-if="!saved">
      <select ref="parentSelect" class="form-select" v-model="selected_parent">
        <option :value="null" disabled>Select a category</option>
        <option
          v-for="category in available_parents"
          :key="category.categoryid"
          :value="category"
        >
          {{ category.categoryname }}
        </option>
      </select>
    </div>

    <div
      :class="[!saved && done ? 'col-md-5' : 'col-md-6']"
      v-if="!saved && child_categories && child_categories.length > 0"
    >
      <select class="form-select" v-model="selected_child">
        <option :value="null" disabled>Select a sub-category</option>

        <option
          v-for="category in child_categories"
          :key="category.categoryid"
          :value="category"
        >
          {{ category.categoryname }}
        </option>
      </select>
    </div>

    <div class="col-md-2" v-if="!saved && done">
      <button class="btn btn-outline" @click="save">Cancel</button>
    </div>

    <div class="col-md-12" v-if="saved">
      <div class="d-flex align-items-center">
        <p class="me-auto mb-0">
          {{ selected_parent.categoryname }}
          <span v-if="selected_child">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-arrow-right"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <line x1="13" y1="18" x2="19" y2="12"></line>
              <line x1="13" y1="6" x2="19" y2="12"></line>
            </svg>
            {{ selected_child.categoryname }}
          </span>
        </p>

        <a
          href="#"
          class="btn btn-outline-primary btn-icon me-2"
          v-if="showEdit"
          @click.prevent="change"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-edit"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
            ></path>
            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
            <line x1="16" y1="5" x2="19" y2="8"></line>
          </svg>
        </a>
        <a
          href="#"
          class="btn btn-outline-danger btn-icon"
          @click.prevent="remove"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-trash"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1="4" y1="7" x2="20" y2="7"></line>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-your-company.add-category-row",
  props: {
    site: Object,
    value: Object,
    autofocus: Boolean,
    parent_categories: Array,
    category_map: Object,
    showEdit: Boolean,
    selected: Array,
  },
  data() {
    return {
      selected_parent: null,
      selected_child: null,
      child_categories: null,
      company: null,
      saved: false,
      loaded: false,
    };
  },
  computed: {
    available_parents() {
      if (!this.parent_categories) {
        return [];
      }
      return this.parent_categories.filter(
        (pc) => this.category_map[pc.categoryid]
      );
    },
    done() {
      if (!this.selected_parent) return false;
      if (!this.loaded) return false;
      if (this.child_categories && this.child_categories.length > 0) {
        if (!this.selected_child) return false;
      }
      return true;
    },
  },
  methods: {
    getChildCategories(parentCategory) {
      return this.category_map[parentCategory.categoryid];
    },
    save() {
      let exists =
        this.selected.filter((s) => {
          if (!s.parent) {
            // self
            return false;
          }

          let sameParent =
            s.parent.categoryid == this.selected_parent.categoryid;
          let sameChild = false;
          if (sameParent) {
            if (!this.selected_child && !s.child) {
              // both don't have a child
              sameChild = true;
            } else if (this.selected_child && s.child) {
              // both have a child selected and the child is the same or not
              sameChild = this.selected_child.categoryid == s.child.categoryid;
            }
            // one has a child and one doesn't, so not the same
          }

          return sameParent && sameChild;
        }).length > 0;

      if (!exists) {
        this.saved = true;
        this.$emit("input", {
          id: this.value.id,
          parent: this.selected_parent,
          child: this.selected_child,
          saved: true,
        });
      } else {
        this.remove();
      }
    },
    async change() {
      this.saved = false;
      this.loaded = false;
      this.$emit("input", {
        id: this.value.id,
      });
      this.$nextTick(() => {
        if (!this.child_categories) {
          this.child_categories = this.getChildCategories(this.selected_parent);
        }
        this.loaded = true;
      });
    },
    remove() {
      this.$emit("remove");
    },
    addWatcher() {
      this.$watch("selected_parent", async function () {
        this.loaded = false;
        this.child_categories = this.getChildCategories(this.selected_parent);
        this.loaded = true;
        this.selected_child = null;
      });
    },
  },
  watch: {
    selected_parent() {
      this.$nextTick(() => {
        if (this.done) {
          this.save();
        }
      });
    },
    selected_child() {
      this.$nextTick(() => {
        if (this.done) {
          this.save();
        }
      });
    },
  },
  async mounted() {
    this.company = this.$auth.activeCompany.companyId;
    if (this.value && this.value.parent) {
      this.selected_parent = this.value.parent;
      this.saved = true;
    }
    if (this.value && this.value.child) {
      this.selected_child = this.value.child;
      this.saved = true;
    }

    this.addWatcher();
  },
};
</script>
