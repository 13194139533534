<template>
  <b-modal
    size="md"
    v-if="site"
    :id="`archive-content-modal-${site.siteid}`"
    :hide-header="true"
    :hide-footer="true"
  >
    <template #default="{ close }">
      <div class="modal-status bg-danger"></div>

      <button type="button" class="btn-close" @click.prevent="close()"></button>

      <!-- Download SVG icon from http://tabler-icons.io/i/alert-triangle -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon mb-2 text-danger icon-lg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 9v2m0 4v.01"></path>
        <path
          d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
        ></path>
      </svg>
      <h3>You've reached your content limit</h3>
      <div class="text-muted">
        Your {{ listingType }} listing in the {{ site.sitename }} includes a
        limited amount of content.
      </div>

      <div class="text-red mb-3">
        <strong>
          In order to post more content to your listing, please select content
          to archive.
        </strong>
      </div>

      <div class="mb-4">
        <div
          class="form-check"
          v-for="whitepaper in whitepapers"
          :key="`whitepaper-archive-${site.siteid}-${whitepaper.whitePaperId}`"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="whitepaper.archive"
            :id="`whitepaper-check-${site.siteid}-${whitepaper.whitePaperId}`"
          />
          <label
            class="form-check-label"
            :for="`whitepaper-check-${site.siteid}-${whitepaper.whitePaperId}`"
          >
            {{ whitepaper.title }}
          </label>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <a target="_blank" :href="`/upgrade?site=${site.siteid}`">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-bolt"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <polyline
                points="13 3 13 10 19 10 11 21 11 14 5 14 13 3"
              ></polyline>
            </svg>
            Remove Content Limits
          </span>
        </a>

        <a
          :class="[
            'btn',
            'btn-primary',
            toArchive.length >= mustArchive ? '' : 'disabled',
          ]"
          href="#"
          @click.prevent="archive(close)"
        >
          <span v-if="toArchive.length >= mustArchive">Archive Content</span>
          <span v-else>Select {{ mustArchive - toArchive.length }} more</span>
        </a>
      </div>
    </template>
  </b-modal>
</template>

<script>
import WhitePaperService from "../../../services/WhitePaperService";
export default {
  name: "content.utility.archivecontentmodal",
  props: {
    site: Object,
    remove: Number,
  },
  data() {
    return {
      whitepapers: [],
    };
  },
  watch: {
    async site() {
      await this.loadSiteWhitepapers();
    },
  },
  methods: {
    async loadSiteWhitepapers() {
      this.whitepapers = (
        await WhitePaperService.getWhitepapersForSite(
          this.$auth.activeCompany.companyId,
          this.site.siteid
        )
      ).live.map((w) => {
        w.archive = false;
        return w;
      });
    },
    archive(close) {
      this.$emit("archive", this.toArchive);
      close();
    },
  },
  computed: {
    listingType() {
      return this.$auth.activeCompany.sites.filter(
        (s) => s.siteID == this.site.siteid
      )[0].listingType;
    },
    toArchive() {
      return this.whitepapers.filter((w) => w.archive);
    },
    mustArchive() {
      // remove should be a negative number
      return Math.abs(this.remove - 1);
    },
  },
  async mounted() {
    await this.loadSiteWhitepapers();
  },
};
</script>
