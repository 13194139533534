<template>
  <div v-if="loaded">
    <add-category-row
      v-for="(category, idx) in value"
      :key="`category-${category.id}`"
      v-model="value[idx]"
      :selected="value"
      :category_map="availableCategoryMap"
      :site="site"
      :parent_categories="parent_categories"
      :showEdit="!inAllCategories"
      @remove="remove(idx)"
    ></add-category-row>
    <button
      :class="['btn', 'btn-outline', 'mt-1']"
      @click="addCategory"
      v-if="canAddCategory"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-circle-plus"
        width="44"
        height="44"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#2c3e50"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <line x1="9" y1="12" x2="15" y2="12" />
        <line x1="12" y1="9" x2="12" y2="15" />
      </svg>
      <span v-if="value && value.length > 0">Add another category</span>
      <span v-else>Add a category</span>
    </button>
    <archive-content-modal
      v-if="site"
      :site="site"
      :remove="contentRemaining"
      @archive="archive"
    ></archive-content-modal>
  </div>
</template>

<script>
import AddCategoryRow from "./AddCategoryRow.vue";
import CategoryService from "../../../services/CategoryService";
import ArchiveContentModal from "../Utility/ArchiveContentModal.vue";

export default {
  name: "add-your-company.category-select",
  components: {
    AddCategoryRow,
    ArchiveContentModal,
  },
  props: {
    value: Array,
    site: Object,
    contentRemaining: Number,
    maxCategories: Number,
  },
  data() {
    return {
      categoryId: 0,
      parent_categories: null,
      category_map: null,
      loaded: false,
    };
  },
  computed: {
    availableCategoryMap() {
      let available = {};
      if (!this.category_map || !this.loaded) return available;
      let parents = Object.keys(this.category_map);
      for (let i = 0; i < parents.length; i++) {
        let parent = this.parent_categories.filter(
          (p) => p.categoryid == parents[i]
        )[0];
        let children = this.category_map[parents[i]];
        if (children.length == 0) {
          let parentSelected =
            this.value.filter(
              (sc) => sc.parent && sc.parent.categoryid == parent.categoryid
            ).length > 0;
          if (!parentSelected) {
            available[parent.categoryid] = [];
          }
        } else {
          let selectedChildren = this.value
            .filter(
              (sc) =>
                sc.parent &&
                sc.child &&
                sc.parent.categoryid == parent.categoryid
            )
            .map((sc) => sc.child.categoryid);
          let remainingChildren = [];
          for (let j = 0; j < children.length; j++) {
            let child = children[j];
            if (!selectedChildren.includes(child.categoryid)) {
              remainingChildren.push(child);
            }
          }

          if (remainingChildren.length > 0) {
            available[parent.categoryid] = remainingChildren;
          }
        }
      }
      return available;
    },
    inAllCategories() {
      if (!this.loaded) return false;
      let parents = Object.keys(this.category_map);
      let total = 0;
      for (let i = 0; i < parents.length; i++) {
        if (this.category_map[parents[i]].length == 0) {
          total += 1;
        } else {
          total += this.category_map[parents[i]].length;
        }
      }
      return total == this.value.length;
    },
    canAddCategory() {
      if (
        this.value &&
        this.maxCategories !== null &&
        this.value.length >= this.maxCategories
      )
        return false;
      if (!this.value) return true;
      return (
        this.loaded &&
        !this.inAllCategories &&
        this.value.filter((c) => !c.parent).length == 0
      );
    },
  },
  methods: {
    addCategory() {
      if (
        this.contentRemaining === null ||
        this.contentRemaining > 0 ||
        this.value.length > 0
      ) {
        this.value.push({
          id: this.categoryId,
        });
        this.categoryId += 1;
      } else {
        this.$bvModal.show(`archive-content-modal-${this.site.siteid}`);
      }
    },
    remove(index) {
      this.value.splice(index, 1);
    },
    archive(content) {
      this.$emit("archive", content);
    },
  },
  async mounted() {
    this.parent_categories = await CategoryService.getSiteCategoryListings(
      this.site.siteid,
      this.$auth.activeCompany.companyId
    );
    this.category_map = await CategoryService.getSiteAllCategoryListings(
      this.site.siteid,
      this.$auth.activeCompany.companyId
    );
    this.categoryId = this.value ? this.value.length + 1 : 1;
    this.loaded = true;
  },
};
</script>
