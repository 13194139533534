<template>
  <div class="card mb-2 mt-2" v-if="loaded">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <p class="me-auto">
          <strong>Share on the {{ fullSite.sitename }} </strong>
        </p>

        <p class="me-1" v-if="value">
          ({{ value.length }} {{ category }}
          )
        </p>
        <p>
          <a href="#" @click.prevent="expand">
            <span v-if="expanded">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-up"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="6 15 12 9 18 15"></polyline>
              </svg>
            </span>

            <span v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-chevron-down"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </span>
          </a>
        </p>
      </div>
      <category-select
        :site="fullSite"
        v-model="value"
        :content-remaining="contentRemaining"
        :max-categories="maxCategories"
        @archive="archive"
      ></category-select>
    </div>
  </div>
</template>

<script>
import SiteService from "../../../services/SiteService";
import CategorySelect from "./CategorySelect.vue";

export default {
  components: { CategorySelect },
  name: "content.upload.site-select-item",
  props: {
    site: Object,
    value: Array,
    contentRemaining: Number,
    maxCategories: Number,
  },
  data() {
    return {
      fullSite: {},
      expanded: true,
      loaded: false,
    };
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
    archive(content) {
      this.$emit("archive", {
        content: content,
        site: this.fullSite,
      });
    },
  },
  computed: {
    category() {
      return this.value.length == 1 ? "category" : "categories";
    },
  },
  async mounted() {
    this.fullSite = await SiteService.getSite(this.site.siteID);
    if (this.value != null && this.value.length > 0) {
      this.expanded = false;
    }
    this.loaded = true;
  },
};
</script>
