import APIService from "./APIService";

const http = APIService.http;

export default {
  async getAllSites() {
    let res = await http.get("api/sites/all");
    return res.data;
  },
  async getSite(siteid) {
    let res = await http.get(`api/sites/detail/${siteid}`);
    return res.data;
  },
  async getSiteSeo(siteid) {
    let res = await http.get(`api/sites/seo/${siteid}`);
    return res.data;
  },
  async getSitePricing(siteid) {
    let res = await http.get(`api/sites/pricing/${siteid}`);
    return res.data;
  },
};
